/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GetWeatherdataSortBy = typeof GetWeatherdataSortBy[keyof typeof GetWeatherdataSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWeatherdataSortBy = {
  date: 'date',
  '-date': '-date',
  createdAt: 'createdAt',
  '-createdAt': '-createdAt',
} as const;
