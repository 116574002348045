import { createGlobalStyle } from "styled-components";

const GlobalApplicationStyles = createGlobalStyle`
  @supports (scrollbar-gutter: stable) {
    html {
      scrollbar-gutter: stable;
      // NOTE: Forcibly set "padding-right: 0;" to prevent React Aria adding extra padding for
      // scroll-locked overlays already accounted for by setting "scrollbar-gutter: stable;"
      // @see https://github.com/adobe/react-spectrum/issues/5470
      padding-right: 0 !important;
    }
  }

  html, body {
    height: 100%;
    width: 100%;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

export default GlobalApplicationStyles;
