/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppIndividualTreatmentComparisonBrigadeScenario = typeof AppIndividualTreatmentComparisonBrigadeScenario[keyof typeof AppIndividualTreatmentComparisonBrigadeScenario];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppIndividualTreatmentComparisonBrigadeScenario = {
  RR: 'RR',
  RT: 'RT',
} as const;
