/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditAllOfTargetType = typeof AppAuditAllOfTargetType[keyof typeof AppAuditAllOfTargetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditAllOfTargetType = {
  bfmc: 'bfmc',
  input: 'input',
  output: 'output',
  project: 'project',
  run: 'run',
  user: 'user',
} as const;
