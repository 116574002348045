import { transformAndCheckStatus } from "@kablamo/kerosene";
import { useEffect, useState } from "react";
import catchAbortError from "../../utils/catchAbortError/catchAbortError";
import getPublicRuntimeConfig from "../../utils/getPublicRuntimeConfig/getPublicRuntimeConfig";
import useAuthAccessToken from "../useAuthAccessToken";

const { INTERCOM_APP_ID } = getPublicRuntimeConfig();

export function useIntercomUserHash() {
  const accessToken = useAuthAccessToken();
  const [userHash, setUserHash] = useState<string | null>(null);
  useEffect(() => {
    if (!INTERCOM_APP_ID || !accessToken) return;

    const abortController = new AbortController();
    fetch("/api/intercom-hmac", {
      signal: abortController.signal,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(transformAndCheckStatus<{ userHash: string }>)
      .then((data) => {
        setUserHash(data.userHash);
      })
      .catch(catchAbortError)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message);
      });

    return () => {
      abortController.abort();
    };
  }, [accessToken]);
  return userHash;
}
