/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAssetGroup = typeof AppAssetGroup[keyof typeof AppAssetGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAssetGroup = {
  'Residential_&_SFPP': 'Residential & SFPP',
  Agriculture: 'Agriculture',
  Building: 'Building',
  Catchments: 'Catchments',
  Electricity: 'Electricity',
  Infrastructure: 'Infrastructure',
  Mining: 'Mining',
  Plantations: 'Plantations',
  Recreation: 'Recreation',
} as const;
