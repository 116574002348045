const publicRuntimeConfig = {
  ENVIRONMENT: process.env.ENVIRONMENT! as
    | "local"
    | "dev"
    | "uat"
    | "trn"
    | "prod"
    | "sandbox"
    | (string & {}),
  IGNORE_ENVIRONMENT_INDICATOR:
    process.env.IGNORE_ENVIRONMENT_INDICATOR === "true",
  REST_API_URL: process.env.REST_API_URL!,
  NEXT_APP_DOMAIN: process.env.NEXT_APP_DOMAIN!,
  NEXT_HERE_API_KEY: process.env.NEXT_HERE_API_KEY!,
  NEXT_OKTA_CLIENT_ID: process.env.NEXT_OKTA_CLIENT_ID!,
  NEXT_OKTA_ISSUER: process.env.NEXT_OKTA_ISSUER!,
  NEXT_OKTA_ENABLED: process.env.NEXT_OKTA_ENABLED === "true",
  AMAZON_ATHENA_URL: process.env.AMAZON_ATHENA_URL!,
  // NOTE: This environment variable is stored in SSM ParameterStore where empty values are not permitted, so we're
  // storing a single space character for environments with no Intercom integration. Using `.trim()` to make falsey.
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID?.trim(),
  INTERCOM_HELP_DOCS_URL: process.env.INTERCOM_HELP_DOCS_URL!,
};

export default publicRuntimeConfig;
