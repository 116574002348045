/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * QA status of an Impact Analysis Run.

Only runs belonging to BFRMP and ITC projects have QA statuses at this time.

Allowed values:
  - `pending` by default (if applicable).
  - `passed` when a user has marked run as passing their organisation's QA.
  - `rejected` when a user has marked run as failing their organisation's QA.

 */
export type AppRunQaStatus = typeof AppRunQaStatus[keyof typeof AppRunQaStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRunQaStatus = {
  pending: 'pending',
  passed: 'passed',
  rejected: 'rejected',
} as const;
