/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * Known Okta RBAC roles
 */
export type AppOktaRole = typeof AppOktaRole[keyof typeof AppOktaRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppOktaRole = {
  RMP_Project_Admin: 'RMP Project Admin',
  RMP_NPWS: 'RMP NPWS',
  RMP_Brigade_User: 'RMP Brigade User',
  RMP_SHM: 'RMP SHM',
  RMP_RA: 'RMP RA',
  RMP_PS: 'RMP PS',
  RMP_AC_CR: 'RMP AC CR',
  RMP_EXEC: 'RMP EXEC',
  RMP_ENV: 'RMP ENV',
  RMP_District_XO: 'RMP District XO',
  RMP_District_Officer: 'RMP District Officer',
  RMP_BNE: 'RMP BNE',
  RMP_BFRP_Users: 'RMP BFRP Users',
  RMP_BFMC: 'RMP BFMC',
  RMP_BFCC: 'RMP BFCC',
  RMP_AFDRS: 'RMP AFDRS',
  RMP_AC_RPO: 'RMP AC RPO',
  RMP_AC_FBAn_AC_RPC: 'RMP AC FBAn AC RPC',
} as const;
