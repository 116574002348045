let config: typeof import("../../publicRuntimeConfig").default;

export default function getPublicRuntimeConfig(): typeof config {
  if (typeof document === "undefined") {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires, import/no-restricted-paths
    return require("../../publicRuntimeConfig").default as typeof config;
  }

  config ??= JSON.parse(
    document.getElementById("__publicRuntimeConfig__")!.textContent!,
  );

  return config;
}
