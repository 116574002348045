/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * The assigned project role
 */
export type ServiceProjectAssignedRole = typeof ServiceProjectAssignedRole[keyof typeof ServiceProjectAssignedRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceProjectAssignedRole = {
  collaborator: 'collaborator',
  owner: 'owner',
  'view-only': 'view-only',
} as const;
