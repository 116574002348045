/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppTreatmentAreaReview = typeof AppTreatmentAreaReview[keyof typeof AppTreatmentAreaReview];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppTreatmentAreaReview = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
} as const;
