/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceCreateAnnualWorksPlanForecastInputAllOfType = typeof ServiceCreateAnnualWorksPlanForecastInputAllOfType[keyof typeof ServiceCreateAnnualWorksPlanForecastInputAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCreateAnnualWorksPlanForecastInputAllOfType = {
  'annual-works-plan-forecast': 'annual-works-plan-forecast',
} as const;
