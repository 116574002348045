import { createGlobalStyle } from "styled-components";

const MapPopupGlobalStyles = createGlobalStyle`
  :is(.mapboxgl-popup-contextual, .maplibregl-popup-contextual) {
    z-index: ${(p) => p.theme.zIndexes.mapItem};
    opacity: 0;

    /* Styled-components nesting rules require the & here to properly target */
    & :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      padding: 0.75rem;
      border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
      box-shadow: ${(p) => p.theme.boxShadows.md};
      color: ${(p) => p.theme.colors.neutrals.text};
      background-color: ${(p) => p.theme.colors.neutrals.background};
    }

    &.full-width {
      width: 100%;
    }

    &:not(.full-width) :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      padding: 0.5rem 0.75rem;
    }

    &.hover {
      z-index: ${(p) => p.theme.zIndexes.hoverMapItem};
    }

    &.no-tip :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      display: none;
    }

    &.inverse :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      color: ${(p) => p.theme.colors.neutrals.textInverse};
      background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-bottom, .maplibregl-popup-anchor-bottom) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-top-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-bottom-left, .maplibregl-popup-anchor-bottom-left) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-top-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-bottom-right, .maplibregl-popup-anchor-bottom-right) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-top-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-top, .maplibregl-popup-anchor-top) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-bottom-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-top-left, .maplibregl-popup-anchor-top-left) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-bottom-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-top-right, .maplibregl-popup-anchor-top-right) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-bottom-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-left, .maplibregl-popup-anchor-left) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-right-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.inverse:is(.mapboxgl-popup-anchor-right, .maplibregl-popup-anchor-right) :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      border-left-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    }

    &.loaded {
      opacity: 1;
    }

    &:is(.mapboxgl-popup-anchor-top-right, .maplibregl-popup-anchor-top-right) :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      border-top-right-radius: 0;
    }

    &:is(.mapboxgl-popup-anchor-top-left, .maplibregl-popup-anchor-top-left) :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      border-top-left-radius: 0;
    }

    &:is(.mapboxgl-popup-anchor-bottom-right, .maplibregl-popup-anchor-bottom-right) :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      border-bottom-right-radius: 0;
    }

    &:is(.mapboxgl-popup-anchor-bottom-left, .maplibregl-popup-anchor-bottom-left) :is(.mapboxgl-popup-content, .maplibregl-popup-content) {
      border-bottom-left-radius: 0;
    }
  }
`;

export default MapPopupGlobalStyles;
