/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAssetCostMetric = typeof AppAssetCostMetric[keyof typeof AppAssetCostMetric];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAssetCostMetric = {
  per_km: 'per km',
  each: 'each',
  one_per_grid_cell: 'one per grid cell',
  per_180m2_grid: 'per 180m2 grid',
  per_hectare: 'per hectare',
} as const;
