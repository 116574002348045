/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GetWeatherzonesSortBy = typeof GetWeatherzonesSortBy[keyof typeof GetWeatherzonesSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWeatherzonesSortBy = {
  createdAt: 'createdAt',
  '-createdAt': '-createdAt',
  name: 'name',
  '-name': '-name',
  updatedAt: 'updatedAt',
  '-updatedAt': '-updatedAt',
} as const;
