/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * String which represents the driver for this weather data item: * T = temperature * W = wind * WC = wind change

 */
export type AppWeatherDriver = typeof AppWeatherDriver[keyof typeof AppWeatherDriver];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppWeatherDriver = {
  TEMPERATURE: 'T',
  WIND: 'W',
  WIND_CHANGE: 'WC',
} as const;
