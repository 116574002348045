import { useGetPermissions } from "../.rest-hooks/user-management";
import deserializeJsonApi from "../utils/deserializeJsonApi/deserializeJsonApi";

export function useCurrentUser() {
  return useGetPermissions({
    query: {
      select: (response) => deserializeJsonApi(response.data).data,
    },
  });
}
