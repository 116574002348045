import { Alert, Spinner } from "@kablamooss/geo-ds-core-components";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import AuthLayout from "../../layout/AuthLayout/AuthLayout";

interface AuthBarrierProps {
  children: React.ReactNode;
  error: Error | undefined;
  isLoading: boolean;
}

const AuthBarrier = ({ children, error, isLoading }: AuthBarrierProps) => {
  if (!isLoading && !error) {
    return <>{children}</>;
  }

  let content;

  if (isLoading) {
    content = <Spinner />;
  }

  if (error) {
    content = (
      <Alert title="Authentication Error" variant="error">
        {error.message ?? "Unknown"}
        <div>{DEFAULT_RMP_ERROR_MESSAGE}</div>
      </Alert>
    );
  }

  return <AuthLayout>{content}</AuthLayout>;
};

export default AuthBarrier;
