/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAssetShape = typeof AppAssetShape[keyof typeof AppAssetShape];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAssetShape = {
  Line: 'Line',
  Point: 'Point',
  Polygon: 'Polygon',
} as const;
