/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * Enum determining the type of run.
* `Phoenix` - Fire Behaviour Run (FBR) * `PhoenixPostprocessing` - Impact Analysis Run (IAR)

 */
export type AppRunType = typeof AppRunType[keyof typeof AppRunType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRunType = {
  Phoenix: 'Phoenix',
  PhoenixPostprocessing: 'PhoenixPostprocessing',
} as const;
