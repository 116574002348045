/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppBfrmpProjectAllOfType = typeof AppBfrmpProjectAllOfType[keyof typeof AppBfrmpProjectAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppBfrmpProjectAllOfType = {
  bfrmp: 'bfrmp',
} as const;
