/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppUserRelationshipObjectAllOfDataType = typeof AppUserRelationshipObjectAllOfDataType[keyof typeof AppUserRelationshipObjectAllOfDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppUserRelationshipObjectAllOfDataType = {
  user: 'user',
} as const;
