/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppPostprocessingSettingsVersion = typeof AppPostprocessingSettingsVersion[keyof typeof AppPostprocessingSettingsVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppPostprocessingSettingsVersion = {
  NUMBER_1: 1,
} as const;
