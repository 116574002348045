import useIntercom from "../../../hooks/useIntercom/useIntercom";

interface IntercomSetupProps {
  children: React.ReactNode;
}

const IntercomSetup = ({ children }: IntercomSetupProps) => {
  useIntercom();
  return <>{children}</>;
};

export default IntercomSetup;
