/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GetWeatherpackagesSortBy = typeof GetWeatherpackagesSortBy[keyof typeof GetWeatherpackagesSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWeatherpackagesSortBy = {
  createdAt: 'createdAt',
  '-createdAt': '-createdAt',
} as const;
