import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useRef, useState } from "react";
import AuthBarrier from "../../util/AuthBarrier/AuthBarrier";

interface AuthSetupProps {
  children: React.ReactNode;
}

const AuthSetup = ({ children }: AuthSetupProps) => {
  const { authState, oktaAuth: auth } = useOktaAuth();

  // NOTE: isAuthenticated uses the `getAccessToken()` method because it is available immediately whereas later on we
  // check `authState?.accessToken?.accessToken` because it is not populated immediately
  const isAuthenticated = !!auth.getAccessToken();

  const [isRedirecting, setIsRedirecting] = useState(false);
  const [error, setError] = useState();

  const handleLoginRedirectPromise = useRef<Promise<void>>();
  useEffect(() => {
    if (auth.isLoginRedirect()) {
      if (!handleLoginRedirectPromise.current) setIsRedirecting(true);
      handleLoginRedirectPromise.current ??= auth
        .handleLoginRedirect()
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          handleLoginRedirectPromise.current = undefined;
          setIsRedirecting(false);
        });
    } else if (!isAuthenticated) {
      auth.setOriginalUri(window.location.href);
      void auth.signInWithRedirect();
    }
  }, [isAuthenticated, auth]);

  const isLoading =
    isRedirecting ||
    (!authState?.accessToken?.accessToken && !authState?.error);

  return (
    <AuthBarrier error={error} isLoading={isLoading}>
      {children}
    </AuthBarrier>
  );
};

export default AuthSetup;
