/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceCreateIndividualTreatmentComparisonBrigadeProjectInputAllOfType = typeof ServiceCreateIndividualTreatmentComparisonBrigadeProjectInputAllOfType[keyof typeof ServiceCreateIndividualTreatmentComparisonBrigadeProjectInputAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCreateIndividualTreatmentComparisonBrigadeProjectInputAllOfType = {
  'individual-treatment-comparison-brigade': 'individual-treatment-comparison-brigade',
} as const;
