/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceCreateIndividualTreatmentComparisonProjectInputAllOfType = typeof ServiceCreateIndividualTreatmentComparisonProjectInputAllOfType[keyof typeof ServiceCreateIndividualTreatmentComparisonProjectInputAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCreateIndividualTreatmentComparisonProjectInputAllOfType = {
  'individual-treatment-comparison': 'individual-treatment-comparison',
} as const;
