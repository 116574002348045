import {
  shouldForwardProp,
  SkeletonTheme,
  ThemeProvider,
  ToastContainer,
} from "@kablamooss/geo-ds-core-components";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StyleSheetManager } from "styled-components";
import GlobalApplicationStyles from "../../../lib/styled";
import MapPopupGlobalStyles from "../../map/Popup/SpatialPopup/MapPopupGlobalStyles";

const SHOW_DEV_TOOLS = globalThis?.location?.search.includes("devtools");

interface AppSetupProps {
  children: React.ReactNode;
}

const AppSetup = ({ children }: AppSetupProps) => {
  return (
    <ThemeProvider>
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={shouldForwardProp}
      >
        <MapPopupGlobalStyles />
        <GlobalApplicationStyles />
        <ToastContainer />
        <SkeletonTheme>
          {SHOW_DEV_TOOLS && <ReactQueryDevtools />}
          {children}
        </SkeletonTheme>
      </StyleSheetManager>
    </ThemeProvider>
  );
};

export default AppSetup;
