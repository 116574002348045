import { useOktaAuth } from "@okta/okta-react";

const useAuthAccessToken = () => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  if (!accessToken) {
    throw new Error(
      "useAuthAccessToken must be used in an authenticated context",
    );
  }
  return accessToken;
};

export default useAuthAccessToken;
