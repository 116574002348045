import omit from "lodash/omit";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace JsonApi {
  /**
   * JSON:API Type
   * @see https://jsonapi.org/format/#document-resource-objects
   */
  export type Type = string;

  /**
   * JSON:API Id
   * @see https://jsonapi.org/format/#document-resource-objects
   */
  export type Id = string;

  /**
   * JSON:API Attributes
   *
   * NOTE: This is not `Record<string, unknown>` or `{ [key: string]: unknown }` as they do not
   * permit inference correctly due to the presence of an index signature
   *
   * @see https://jsonapi.org/format/#document-resource-object-attributes
   */
  export type Attributes = object;

  export type BareItem<TType extends Type = Type> = {
    id: Id;
    type: TType;
  };

  /**
   * JSON:API Resource Object Linkage
   * @see https://jsonapi.org/format/#document-resource-object-linkage
   */
  export type RelationshipObject<TType extends Type = Type> = {
    data: BareItem<TType>;
  };

  /**
   * JSON:API Resource Object Linkage (single or multiple)
   * @see https://jsonapi.org/format/#document-resource-object-linkage
   */
  export type Relationship<TType extends Type = Type> =
    | RelationshipObject<TType>
    | readonly RelationshipObject<TType>[];

  /**
   * JSON:API Relationships
   *
   * TODO: Support `null` for empty one-to-one relationships
   *
   * @see https://jsonapi.org/format/#document-resource-object-linkage
   */
  export type Relationships = Partial<Record<string, Relationship>> | undefined;

  /**
   * JSON:API Included (Compound Documents)
   * @see https://jsonapi.org/format/#document-compound-documents
   */
  export type Included = readonly Item[];

  /**
   * JSON:API Resource Object
   * @see https://jsonapi.org/format/#document-resource-objects
   */
  export type Item<
    TType extends Type = Type,
    TAttributes extends Attributes = Attributes,
    // eslint-disable-next-line @typescript-eslint/ban-types
    TRelationships extends Relationships = Relationships,
  > = BareItem<TType> & {
    attributes: TAttributes;
    relationships?: TRelationships;
  };

  /**
   * JSON:API Single item response (Compound Documents)
   * @see https://jsonapi.org/format/#document-top-level
   * @see https://jsonapi.org/format/#fetching-resources-responses-200
   * @see https://jsonapi.org/format/#document-compound-documents
   */
  export type OneResponse<
    TItem extends Item = Item,
    TIncluded extends Included = Included,
  > = {
    data: TItem;
    included: TIncluded;
  };

  /**
   * JSON:API Collection response (Compound Documents)
   * @see https://jsonapi.org/format/#document-top-level
   * @see https://jsonapi.org/format/#fetching-resources-responses-200
   * @see https://jsonapi.org/format/#document-compound-documents
   */
  export type ManyResponse<
    TItem extends Item = Item,
    TIncluded extends readonly Item[] = readonly Item[],
  > = {
    data: readonly TItem[];
    included: TIncluded;
  };

  /**
   * Utility type that resolves from `TIncluded` any matching `Item` types according to `TType`
   */
  export type ResolveRelationship<
    TType extends Type,
    TIncluded extends Included,
  > = DeserializeItem<Extract<TIncluded[number], { type: TType }>, TIncluded>;

  /**
   * Utility type that resolves from `TRelationships` all relationships from `TIncluded` according to `type`
   *
   * TODO: Handle `null` for relationship values
   */
  export type ResolveRelationships<
    TRelationships extends Relationships,
    TIncluded extends Included,
  > = {
    [TRelationshipKey in keyof TRelationships]: Exclude<
      TRelationships[TRelationshipKey],
      undefined
    > extends ReadonlyArray<RelationshipObject<infer TType>>
      ? ReadonlyArray<ResolveRelationship<TType, TIncluded>>
      : Exclude<
            TRelationships[TRelationshipKey],
            undefined
          > extends RelationshipObject<infer TType>
        ? ResolveRelationship<TType, TIncluded>
        : never;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  type Simplify<T> = { [K in keyof T]: T[K] } & {};

  export type DeserializeItem<TItem extends Item, TIncluded extends Included> =
    { relationships: undefined } extends Pick<TItem, "relationships">
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
        TItem extends Item<infer _TType, infer TAttributes>
        ? Simplify<TAttributes & { id: TItem["id"] }>
        : never
      : TItem extends Item<
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            infer _TType,
            infer TAttributes,
            infer TRelationships
          >
        ? Simplify<
            TAttributes &
              ResolveRelationships<TRelationships, TIncluded> & {
                id: TItem["id"];
              }
          >
        : never;

  export type DeserializeOne<TResponse extends OneResponse> =
    TResponse extends OneResponse<infer TItem, infer TIncluded>
      ? Omit<TResponse, "data" | "included"> & {
          data: DeserializeItem<TItem, TIncluded>;
        }
      : never;
  export type DeserializeMany<TResponse extends ManyResponse> =
    TResponse extends ManyResponse<infer TItem, infer TIncluded>
      ? Omit<TResponse, "data" | "included"> & {
          data: ReadonlyArray<DeserializeItem<TItem, TIncluded>>;
        }
      : never;
  export type Deserialize<TResponse extends OneResponse | ManyResponse> =
    TResponse extends OneResponse
      ? DeserializeOne<TResponse>
      : TResponse extends ManyResponse
        ? DeserializeMany<TResponse>
        : never;
}

type UnknownObject = Record<string, unknown>;

function isArray<T>(value: T): value is Extract<T, readonly unknown[]> {
  return Array.isArray(value);
}

const idSchema = z.string();
const typeSchema = z.string();

const attributesSchema = z.record(z.string(), z.unknown());

const bareItemSchema = z.object({
  id: idSchema,
  type: typeSchema,
});

const relationshipObjectSchema = z
  .object({
    data: bareItemSchema,
  })
  .passthrough();

const relationshipSchema = z.union([
  relationshipObjectSchema,
  z.array(relationshipObjectSchema),
]);

const relationshipsSchema = z.record(z.string(), relationshipSchema);

const itemSchema = bareItemSchema
  .extend({
    attributes: attributesSchema,
    relationships: relationshipsSchema.optional(),
  })
  .passthrough();

const includedSchema = z.array(itemSchema);

const oneResponseSchema = z
  .object({
    data: itemSchema,
    included: includedSchema,
  })
  .passthrough();

const manyResponseSchema = z
  .object({
    data: z.array(itemSchema),
    included: includedSchema,
  })
  .passthrough();

export default function deserializeJsonApi<
  TResponse extends JsonApi.OneResponse | JsonApi.ManyResponse,
>(response: TResponse): JsonApi.Deserialize<TResponse> {
  const result = Array.isArray(response.data)
    ? manyResponseSchema.safeParse(response)
    : oneResponseSchema.safeParse(response);
  if (!result.success) {
    // eslint-disable-next-line no-console
    console.error(
      "deserializeJson(response) was called with nonconforming response:",
      result.error,
    );
    throw result.error;
  }

  const toResolve = [
    ...response.included,
    ...(isArray(response.data) ? response.data : [response.data]),
  ];
  const resolved = new Map<JsonApi.Type, Map<JsonApi.Id, UnknownObject>>();
  toResolve.forEach((item) => {
    if (!resolved.has(item.type)) resolved.set(item.type, new Map());
    const map = resolved.get(item.type)!;
    map.set(item.id, { ...item.attributes });
  });
  // Track which objects are unresolved so we can warn about them only once
  const unresolved = new Map<JsonApi.Type, Set<JsonApi.Id>>();

  toResolve.forEach((item) => {
    if (!item.relationships) return;

    const targetItem = resolved.get(item.type)!.get(item.id)!;

    Object.entries(item.relationships).forEach(
      ([relationshipKey, relationship]) => {
        const resolvedObjects = (
          isArray(relationship) ? relationship : [relationship]
        )
          .filter(
            (
              relationshipObject,
            ): relationshipObject is Exclude<
              typeof relationshipObject,
              null | undefined
            > => !!relationshipObject,
          )
          .flatMap((relationshipObject) => {
            const { type, id } = relationshipObject.data;
            const resolvedRelationship = resolved.get(type)?.get(id);

            if (!resolvedRelationship) {
              if (!unresolved.has(type)) {
                unresolved.set(type, new Set());
              }

              if (!unresolved.get(type)!.has(id)) {
                // eslint-disable-next-line no-console
                console.error(
                  `deserializeJson(response): expected response.included to include an item of type ${JSON.stringify(type)} with id ${JSON.stringify(id)}, but none was found.`,
                );
                unresolved.get(type)!.add(id);
              }

              // Bailing out for now, but this should really be an error
              return;
            }
            return [resolvedRelationship];
          });
        targetItem[relationshipKey] = Array.isArray(relationship)
          ? resolvedObjects
          : resolvedObjects[0];
      },
    );
  });

  toResolve.forEach((item) => {
    resolved.get(item.type)!.get(item.id)!.id = item.id;
  });

  return {
    ...omit(response, ["data", "included"]),
    data: isArray(response.data)
      ? response.data.map((item) => resolved.get(item.type)!.get(item.id)!)
      : resolved.get(response.data.type)!.get(response.data.id)!,
  } as JsonApi.Deserialize<TResponse>;
}
