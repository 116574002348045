import {
  Menu,
  NavHeader,
  NavHeaderButton,
} from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";

const StyledAuthLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <StyledAuthLayout>
      <NavHeader
        nav={
          <NavHeaderButton
            icon={Menu}
            label="Open navigation drawer"
            disabled
          />
        }
        title="Risk modelling project"
      />
      <StyledInner>{children}</StyledInner>
    </StyledAuthLayout>
  );
};

export default AuthLayout;
