/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditInputUpdatedEventType = typeof AppAuditInputUpdatedEventType[keyof typeof AppAuditInputUpdatedEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditInputUpdatedEventType = {
  'input-updated': 'input-updated',
} as const;
