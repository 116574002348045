/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAnnualWorksPlanForecastScenario = typeof AppAnnualWorksPlanForecastScenario[keyof typeof AppAnnualWorksPlanForecastScenario];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAnnualWorksPlanForecastScenario = {
  FM: 'FM',
} as const;
